<template>
  <v-container class="video-room text-white" fluid>
    <div v-if="state.loading" class="loading-overlay">
      <p>Loading...</p>
    </div>

    <div v-else-if="!state.isConnected" class="join-container">
      <v-btn color="secondary" class="text-white" @click="joinRoom">Join Room</v-btn>
    </div>

    <div v-show="state.isConnected" style="width: 100%;">

      <!-- Video Grid -->
      <v-row>
        <v-col v-for="participant in state.participants" :key="participant.identity" cols="12">
          <div class="video-wrapper" :ref="el => setVideoRef(el, participant.identity)">
            <p class="participant-name">{{ participant.identity }}</p>
          </div>
        </v-col>
      </v-row>

      <!-- Self Video (Pinned bottom-right) -->
      <div v-if="state.isConnected" class="self-video" ref="selfVideoContainer">
        <p class="participant-name">You</p>
      </div>

      <!-- Toolbar -->
      <v-footer class="toolbar bg-primary">
        <v-btn icon @click="toggleMute" class="mr-2">
          <v-icon>{{ state.isMuted ? 'fas fa-microphone-slash' : 'fas fa-microphone' }}</v-icon>
        </v-btn>
        
        <v-btn icon @click="toggleVideo" class="mr-2">
          <v-icon>{{ state.isVideoOff ? 'fas fa-video-slash' : 'fas fa-video' }}</v-icon>
        </v-btn>
        
        <v-btn icon @click="toggleScreenShare" class="mr-2">
          <v-icon>{{ state.isScreenSharing ? 'fas fa-desktop' : 'fas fa-tv' }}</v-icon>
        </v-btn>
        
        <v-btn icon color="error" @click="leaveRoom">
          <v-icon class="fa-solid fa-phone-slash"></v-icon>
        </v-btn>
      </v-footer>
    </div>

    <p v-if="state.error" class="error">{{ state.error }}</p>
  </v-container>
</template>

<script setup>
import { ref, reactive, onUnmounted, nextTick, defineProps } from "vue";
import TwilioVideo from "twilio-video";
import { connect } from "twilio-video";
import { VideoService } from "@/api/services/video";

const props = defineProps({
  eventId: Number,
});

const selfVideoContainer = ref(null);
const videoRefs = ref({});
const videoService = new VideoService(props.eventId);

const state = reactive({
  loading: false,
  error: null,
  isConnected: false,
  participants: [],
  roomName: `room_${props.eventId}`,
  isMuted: false,
  isVideoOff: false,
  isScreenSharing: false,
  newMessage: "",
});

let room = null;
let screenTrack = null;

// Set participant video ref
const setVideoRef = (el, participantId) => {
  if (el) videoRefs.value[participantId] = el;
};

// Join Room
const joinRoom = async () => {
  state.loading = true;
  state.error = null;

  try {
    const response = await videoService.getToken();
    const { token, room_name } = response;
    state.roomName = room_name;

    room = await connect(token, { name: room_name, audio: true, video: true });

    await nextTick();

    room.localParticipant.tracks.forEach((publication) => {
      if (publication.track && selfVideoContainer.value) {
        selfVideoContainer.value.innerHTML = "";
        selfVideoContainer.value.appendChild(publication.track.attach());
      }
    });

    room.participants.forEach(participantConnected);
    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);

    state.isConnected = true;
  } catch (e) {
    state.error = "Error joining room.";
  } finally {
    state.loading = false;
  }
};

// Attach participant video
const attachTrack = (track, participantId) => {
  nextTick(() => {
    const videoWrapper = videoRefs.value[participantId];
    if (videoWrapper && track.kind === "video") {
      videoWrapper.innerHTML = "";
      videoWrapper.appendChild(track.attach());
    }
  });
};

// Participant events
const participantConnected = (participant) => {
  state.participants.push({ identity: participant.identity });

  participant.tracks.forEach((publication) => {
    if (publication.isSubscribed) {
      attachTrack(publication.track, participant.identity);
    }
  });

  participant.on("trackSubscribed", (track) => attachTrack(track, participant.identity));
};

const participantDisconnected = (participant) => {
  state.participants = state.participants.filter((p) => p.identity !== participant.identity);
};

// Leave Room
const leaveRoom = () => {
  if (room) {
    room.disconnect();
    state.isConnected = false;
    state.participants = [];
    videoRefs.value = {};
  }
};

// Mute/Unmute Audio
const toggleMute = () => {
  state.isMuted = !state.isMuted;
  room.localParticipant.audioTracks.forEach((track) => {
    track.track.enable(!state.isMuted);
  });
};

// Enable/Disable Video
const toggleVideo = () => {
  state.isVideoOff = !state.isVideoOff;
  room.localParticipant.videoTracks.forEach((track) => {
    track.track.enable(!state.isVideoOff);
  });
};

// Screen Sharing
const toggleScreenShare = async () => {
  if (state.isScreenSharing) {
    screenTrack.stop();
    room.localParticipant.unpublishTrack(screenTrack);
    state.isScreenSharing = false;
  } else {
    const stream = await navigator.mediaDevices.getDisplayMedia();
    screenTrack = new TwilioVideo.LocalVideoTrack(stream.getTracks()[0]);
    room.localParticipant.publishTrack(screenTrack);
    state.isScreenSharing = true;
  }
};


onUnmounted(() => {
  if (room) room.disconnect();
});
</script>

<style scoped>
  .video-room {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }
  
  .loading-overlay {
    font-size: 18px;
    font-weight: bold;
  }
  
  .join-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  
  .room-container {
    width: 100%;
    max-width: 1200px;
  }
  
  .room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .leave-btn {
    font-weight: bold;
  }
  
  .video-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    width: 100%;
    max-width: 1000px;
    margin: auto;
  }
  
  .video-tile {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  .video-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .participant-name {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.5);
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .waiting-msg {
    margin-top: 15px;
    font-size: 16px;
    color: #ccc;
  }
  
  .error {
    color: red;
    margin-top: 15px;
    font-size: 16px;
  }
  </style>