<template>
  <v-container v-if="state.document">
    <v-sheet max-width="600" class="mx-auto mb-5" v-if="state.document.status === 'complete'">
      <v-alert type="success" elevation="2" class="mx-2" color="success" dark>
        <v-row no-gutters>
          <v-col cols="12">
            <p class="text-h6">Form Submitted</p>
            <p class="text-body-1">You have already submitted this form, you cannot edit it.</p>
          </v-col>
        </v-row>
      </v-alert>
    </v-sheet>
    <v-sheet class="mx-auto px-3">
      <v-form validate-on="submit lazy" :disabled="state.document.status === 'complete'">
        <template v-for="(item, index) in state.document.data.items" :key="index">
          <template v-if="item.type === 'section'">
            <form-field :item="item">
              <template v-for="(subItem, subIndex) in getSectionItems(index)" :key="subIndex">
                <span v-if="subItem.type === 'text'" v-html="processLinks(subItem.content)" class="text-wrap"></span>
                <form-field v-else v-model="state.document.data.items[subItem.originalIndex].value" :item="subItem"/>
              </template>
            </form-field>
          </template>
          <template v-else-if="!isItemInSection(index)">
            <span v-if="item.type === 'text'" v-html="processLinks(item.content)" class="text-wrap"></span>
            <form-field v-else v-model="state.document.data.items[index].value" :item="item"/>
          </template>
        </template>

        <div v-if="state.document.status !== 'complete'">
          <v-btn :loading="settingsStore.loading && state.document.status !== 'complete'"
                 v-if="state.document.document_template.document.type === 'form'"
                 :color="settingsStore.settings.secondaryColor"
                 block class="mt-2" text="Save"
                 @click="submit(false)">
          </v-btn>
          <v-btn v-if="state.document.document_template.document.type === 'form'"
                 :loading="settingsStore.loading && state.document.status === 'complete'"
                 :color="settingsStore.settings.primaryColor"
                 block class="mt-2" text="Save & Submit" @click="submit(true)">
          </v-btn>
        </div>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script setup lang="ts">
import {reactive, computed} from 'vue';
import {documentService} from '@/api/services/document';
import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';

import FormField from '@/components/SwandoolaDocBuilder/FormField.vue';

const router = useRouter();

const settingsStore = useSettingsStore();

const state = reactive({
  document: null as any,
});

const documentId = computed(() => router.currentRoute.value.params.id);

const fetchDocument = async () => {
  documentService.show(documentId.value as string).then((response) => {
    state.document = response;
  })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        settingsStore.loading = false;
      });
};

fetchDocument();

const checkRequiredFields = (data: any) => {
  let allRequiredFieldsValid = true;

  data.items.forEach((item: any) => {
    if (item.isRequired) {
      if (!item.value || (typeof item.value === "string" && item.value.trim() === "")) {
        allRequiredFieldsValid = false;
        console.log(`Required field missing value: ${item.label}`);
      }
    }
  });

  return allRequiredFieldsValid;
}

const processLinks = (content) => {

  if(!content || content.length === 0) {
    return content;
  }
  
  // Regular expression to find URLs in text
  const urlRegex = /\bhttps?:\/\/[^\s<>]+/g;

  return content.replace(urlRegex, url =>
      `<a href="${url}" target="_blank">${url}</a>`
  );
};

const submit = async (completed: boolean) => {

  if (!checkRequiredFields(state.document.data)) {
    alert("Please fill in all required fields.");
    return;
  }

  settingsStore.loading = true;
  const response = await documentService.update(state.document.id, {
    data: state.document.data,
    completed: completed,
  });
  settingsStore.loading = false;
  fetchDocument();
};

const getSectionItems = (sectionIndex: number) => {
  const items = state.document.data.items;
  const nextSectionIndex = items.findIndex((item, index) =>
      index > sectionIndex && item.type === 'section'
  );

  const endIndex = nextSectionIndex === -1 ? items.length : nextSectionIndex;

  return items
      .slice(sectionIndex + 1, endIndex)
      .map((item, index) => ({
        ...item,
        originalIndex: sectionIndex + 1 + index
      }));
};

const isItemInSection = (index: number) => {
  const items = state.document.data.items;
  const previousItems = items.slice(0, index);
  const lastSection = previousItems.findLast(item => item.type === 'section');
  return !!lastSection;
};
</script>

<style scoped>
.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: block;
}

:deep(ol) {
  padding-left: 14px
}
</style>