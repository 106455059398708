import { HttpService } from "./http";

export class VideoService extends HttpService {
    private eventId: number;

    constructor(eventId: number) {
        super("/client-app/video");
        this.eventId = eventId;
    }

    getToken() {
        return this.http.post(this.route + "/token", { event_id: this.eventId })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    getEvent() {
        return this.http.get(this.route + `/event/${this.eventId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const createVideoService = (eventId: number) => new VideoService(eventId);