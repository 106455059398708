<template>
    <v-container>
        <div v-if="state.event" class="row justify-content-center text-center">
            <h2>{{ state.event.name }}</h2>
            <h5>{{ state.event.start_date }} - {{  state.event.end_date }}</h5>
          <video-session :eventId="state.event.id" />
        </div>
    </v-container>
</template>

<script setup lang="ts">
import { reactive, inject } from 'vue';
import { VideoService } from '@/api/services/video';
import { useRoute, useRouter } from 'vue-router';

//UI
import videoSession from '@/components/video-session/video-session.vue';

const route = useRoute();
const router = useRouter();

const snackbar: any = inject('snackbar');

const state = reactive({
    loading: false,
    eventId: 0,
    event: null,
});

const initRoomEvent = () => {
    state.eventId = Number(route.params.eventId);
    const videoService = new VideoService(state.eventId);
    
    console.log("Loading Event", state.eventId);

    videoService.getEvent().then(response => {
        state.event = response.data;
    }).catch(() => {
        snackbar.errorSnackbar("Failed to load event");
    });
}

initRoomEvent();
</script>