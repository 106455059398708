<template>
  <section class="order-card-item-details">
    <v-sheet v-if="getImage" class="logo-container m-0">
      <div :style="{backgroundImage: `url(${getImage})`}" class="logo-container__image"></div>
    </v-sheet>

    <v-sheet v-else :class="{'secondary-background-button-color': supplierId === 'clinic'}" class="icon-container">
      <Icon :icon="getIcon"/>
    </v-sheet>

    <v-card-text class="pa-0">
      <section class="d-flex justify-space-between">
        <p class="font-size-12-600 title-text">{{ getTitle }}</p>

        <div class="text-right font-size-16-600">
          <p>{{ getPrice }}</p>
          <p :class="paymentStatus.color" class="font-size-12-600" style="width: 68px">{{ paymentStatus.status }}</p>
        </div>
      </section>

      <section v-if="supplierOrder.consultation" :class="{'two-line-ellipsis': showMore}">
        <div
            v-for="(consultation, index) in supplierOrder.consultation"
            :key="`consultation-${index}`"
            class="mt-4"
        >

          <p v-if="isPackage" class="font-size-12-600">
            <span class="font-size-12-400 font-grey-dark">({{ index + 1 }})</span>
            {{ consultation.clinic_service.name }}
          </p>

          <div class="mb-3 mt-1">
            <p class="font-size-12-400">
              <span class="font-grey-dark">Status: </span>
              <span class="font-size-12-600">{{ getStatus(consultation) }}</span>
            </p>
          </div>

          <div v-if="getConsultationDate(consultation)" class="d-flex align-center ga-1">
            <Icon icon="calendar-icon"/>
            <p class="font-size-12-600">{{ getConsultationDate(consultation) }}</p>
          </div>

          <div class="d-flex align-center ga-1 mt-1">
            <Icon :icon="getMethodIconOrLabel(consultation).icon"/>
            <p class="font-size-12-600">{{ getMethodIconOrLabel(consultation).label }}</p>
          </div>

          <InstalmentsDetails
              v-if="order.instalments.length > 0 && !isPackage"
              :order="order"
              :supplierOrder="supplierOrder"
          />

          <div v-if="consultation?.assignee" class="mt-4 d-flex align-center ga-1">
            <v-sheet class="avatar-container">
              <div v-if="getAvatar(consultation).image"
                   :style="{ backgroundImage: `url(${getAvatar(consultation).image})` }"
                   class="avatar-container__image"></div>
              <div v-else class="avatar-container__initials primary-background-button-color">
                {{ getAvatar(consultation).initials }}
              </div>
              <v-overlay
                  activator="parent"
                  location-strategy="connected"
                  scroll-strategy="close"
              >
                <v-card class="pa-2">
                  {{ getAvatar(consultation).name }}
                </v-card>
              </v-overlay>
            </v-sheet>

            <p class="font-size-12-600">
              {{ consultation?.assignee.first_name }} {{ consultation?.assignee.last_name }}
            </p>
          </div>

          <div class="d-flex ga-2 mt-3">
            <div>
              <Icon icon="message-icon"></Icon>
            </div>
            <div class="font font-grey-dark">
              {{ getConsultationCommentOrProductDescription(consultation) }}
            </div>
          </div>

          <div v-if="!consultation?.event" class="text-right mt-3">
            <v-btn
                class="b-round-20 outline-btn primary-button mb-2"
                elevation="0"
                height="32px"
                variant="outlined"
                width="82px"
                x-small
                @click="router.push({name: 'book-service', params: { id: consultation.clinic_service_id }})"
            >
              Book
            </v-btn>
          </div>
          <div v-if="consultation?.assignee && consultation.method === 'Video'" class="text-right mt-3">
            <v-btn
                class="b-round-20 outline-btn primary-button mb-2"
                elevation="0"
                height="32px"
                variant="outlined"
                width="82px"
                x-small
                @click="onJoin(consultation)"
            >
              <span class="mr-1">Join</span>
              <Icon icon="video-icon-lg"></Icon>
            </v-btn>
          </div>

          <p v-if="index !== supplierOrder.consultation.length -1" class="border-bottom mt-5"></p>
        </div>
      </section>
    </v-card-text>
  </section>

  <div v-if="supplierOrder.consultation" class="text-right">
    <v-btn
        :ripple="false"
        class="primary-underline-button text-capitalize font-size-12-600 pa-0"
        variant="plain"
        @click="toggleShowMore"
    >
      {{ showMore ? 'Show More' : 'Show Less' }}
    </v-btn>
  </div>
</template>

<script lang="ts" setup>
import {defineProps, ref, computed} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import moment from "moment/moment";
import InstalmentsDetails from "@/views/OrdersComponents/OrderDetails/ServicePackageDetails/InstalmentsDetails.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  paymentStatus: {
    type: Object,
    default: () => ({color: '', status: ''})
  },
  isClinicServiceOrPackage: {
    type: Boolean,
    default: false
  },
  supplierOrder: {
    type: Object,
    default: () => ({})
  },
  order: {
    type: Object,
    default: () => ({})
  },
  supplierId: {
    type: String,
  }
});

const showMore = ref(true);

const toggleShowMore = () => {
  showMore.value = !showMore.value;
};

const getImage = computed(() => {
  // return '/img/empty-square.png';
  return null;
})

const getIcon = computed(() => {
  switch (props.item.type) {
    case 'clinic_service':
    case 'service':
      return 'service-icon';
    case 'package':
    case 'clinic_package':
      return 'package-icon';
  }
  return null;
})

const getTitle = computed(() => {
  return props.item.name ?? '';
})

const getPrice = computed(() => {
  return props.item.price_display ?? '-';
})

const isPackage = computed(() => {
  return props.item.type === 'clinic_package' || props.item.type === 'package'
})

const getConsultationCommentOrProductDescription = (consultation) => {
  return consultation.consultation?.comment ?? consultation.clinic_service?.description;
}

const getMethodIconOrLabel = (consultation) => {
  let icon;
  let label;

  switch (consultation.method) {
    case 'In Person':
    case 'in-person':
      icon = 'location-icon'
      label = 'In Person'
      break
    case 'Phone Call':
      icon = 'phone-icon'
      label = 'Phone Call'
      break
    case 'Video':
      icon = 'video-icon'
      label = 'Video'
      break
    default:
      icon = null
  }

  return {
    'icon': icon,
    'label': label
  }
}

const getConsultationDate = (consultation) => {
  if (consultation?.event !== null) {
    return moment(consultation?.event.start_date).format("HH:mm ddd, MMMM DD") ?? '';
  }

  return null;
}

const getStatus = (consultation) => {
  if (consultation?.confirmed_with_client == false) {
    return 'Unconfirmed';
  }

  return 'Confirmed';
}

const getAvatar = (consultation) => {
  if (consultation.assignee_id && consultation.assignee) {
    const firstName = consultation.assignee.first_name ?? '';
    const lastName = consultation.assignee.last_name ?? '';

    if (consultation.assignee.image) {
      return {
        image: consultation.assignee.image.file?.file_path ?? '',
        initials: null,
        name: `${firstName} ${lastName}`
      };
    }

    if (firstName || lastName) {
      return {
        image: null,
        initials: (firstName[0] + (lastName[0] || '')).toUpperCase(),
        name: `${firstName} ${lastName}`
      };
    }
  }
  return null;
};

const onJoin = (consultation) => {
  if (!consultation.link) {
    router.push({name: 'video-session', params: {eventId: consultation.id}});
  }

  window.location.href = consultation.link;
}
</script>

<style lang="scss" scoped>
.order-card-item-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  margin: 18px 0;

  .logo-container {
    width: 126.61px;
    height: 122.57px;

    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .avatar-container {
    border-radius: 100%;
    width: 31.45px;
    height: 31.45px;

    &__image {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 12px;
      border-radius: 50%;
    }
  }

  .title-text {
    white-space: wrap;
    word-break: break-word;
  }
}

.font {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width {
  width: 235px;
}

.border-bottom {
  border: 0.8px solid #F0F0F0
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-container {
  border-radius: 5px;
  width: 126.61px;
  height: 122.57px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>